<template>
  <v-dialog
    v-model="dialog"
    :max-width="maxWidth"
    content-class="filemgr-dialog"
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
      />
    </template>
    <FileManagerCard>
      <template #actions>
        <v-card-actions class="pa-0 pt-sm-2">
          <v-btn
            color="primary"
            block
            :disabled="disabledImportBtn||isLoadingFile"
            :loading="isLoadingFile"
            @click="fetchFileContent(activeFileNode)"
          >
            載入圖層
          </v-btn>
        </v-card-actions>
      </template>
    </FileManagerCard>
  </v-dialog>
</template>

<script>
import FileManagerCard from './FileManagerCard'
import { FILE_TYPE } from '@/models/utils'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'FileManagerDialog',

  components: {
    FileManagerCard
  },

  provide() {
    return {
      fetchFileContent: this.fetchFileContent
    }
  },

  props: {
    // value: {
    //   type: Boolean,
    //   default: false
    // },
    maxWidth: {
      type: [String, Number],
      default: 1280
    }
  },

  computed: {
    ...mapState({
      fileTree: state => state.files.fileTree,
      activeFileNode: state => state.files.activeFileNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId',
      isLoadingFolders: 'files/isLoadingFolders'
    }),

    dialog: {
      get() {
        return this.$store.state.files.dialog
      },
      set(newVal) {
        this.$store.commit('files/setState', { dialog: newVal })
      }
    },

    project() {
      return this.getProjectByRoute(this.$route)
    },
    root() {
      return this.fileTree?.root
    },

    hasSourceFile() {
      if (!this.activeFileNode) {
        return false
      }

      return this.activeFileNode.type === FILE_TYPE.FILE
    },

    isLoadingFile() {
      if (this.activeFileNode?.type !== FILE_TYPE.FILE) {
        return false
      }

      return this.activeFileNode.isLoading === true
    },

    disabledImportBtn() {
      return !this.hasSourceFile ||
        this.activeFileNode?.maplayerType === 'SETTINGS_FILE' ||
        this.activeFileNode?.hasLoaded ||
        this.activeFileNode?.isLoading === true ||
        // vtk2glb 要檢查mapset permission要可以create
        (
          !this.project.isOwner &&
          this.activeFileNode.isVtkFile &&
          !this.getRoleByResourceId(this.project, this.activeFileNode.parent.uuid)?.create
        )
    }
  },

  watch: {
    dialog: {
      handler(newVal) {
        if (newVal) {
          this.fetchFolders()
        }
      }
    }
  },

  methods: {
    fetchFileContent(fileNode) {
      // NOTE: 這function被provide給FileManager底下任意組件使用
      if (
        fileNode?.type !== FILE_TYPE.FILE ||
        fileNode?.maplayerType === 'SETTINGS_FILE' ||
        fileNode.hasLoaded ||
        fileNode.isLoading === true
      ) {
        return
      }

      // vtk2glb 要檢查mapset permission要可以create
      if (
        !this.project.isOwner &&
        fileNode.isVtkFile &&
        !this.getRoleByResourceId(this.project, fileNode.parent.uuid)?.create
      ) {
        return
      }

      const storeAction = fileNode.isVtkFile
        ? 'createGlbFile'
        : 'fetchFileContent'

      return this.$store.dispatch(`files/${storeAction}`, {
        project: this.project,
        fileNode
      })
        .then(fileNodeWithContent => {
          this.$emit('fileContentLoaded', {
            fileNode: fileNodeWithContent
          })
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
    },
    fetchFolders() {
      if (this.isLoadingFolders || !this.root) {
        return
      }

      // 不指定parentNode就是fetch資料夾
      const parent = this.root
      const project = this.project
      return this.$store.dispatch('files/fetchFileNodes', {
        project,
        parent,
        loading: !this.root.hasChildren
      })
        .catch(error => this.$store.dispatch('snackbar/showError', {
          content: error
        }))
    }
  }
}
</script>

<style lang="scss">
.filemgr-dialog {
  padding: 16px 24px;
  margin: 24px 36px;
  height: 80%;
  background-color: $color-bg-ease-grey;

  @media screen and (max-width: 600px) {
    padding: 0;
    margin: 0 12px;
    height: 50%;
  }
}
</style>

<style lang="scss" scoped></style>
