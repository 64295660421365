var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-row',{staticClass:"geo-params-row",attrs:{"no-gutters":"","align":"start"}},[_c('v-col',{staticClass:"file-col"},[_c('v-input',{attrs:{"value":_vm.geoFile,"rules":_vm.rulesGeoFile,"hide-details":"auto"}},[_c('FileSelect',{attrs:{"simulation-model-uuid":_vm.model,"filter-parent-maplayer":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{staticClass:"secondary--text",staticStyle:{"max-width":"100%"},attrs:{"left":"","color":"transparent","content":"geo","overlap":"","offset-x":"2em"}},[_c('v-chip',_vm._g(_vm._b({attrs:{"color":"grey lighten-3"}},'v-chip',attrs,false),on),[(_vm.geoFile)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
var attrsTooltip = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate"},'div',attrsTooltip,false),onTooltip),[_vm._v(" "+_vm._s(_vm.geoFile.name)+" ")])]}}],null,true)},[_vm._l((_vm.geoFile.breadcrumbs),function(breadcrumb,iBreadcrumb){return [_c('span',{key:iBreadcrumb,staticClass:"text-no-wrap"},[_vm._v(_vm._s(breadcrumb))]),(iBreadcrumb < _vm.geoFile.breadcrumbs.length - 1)?_c('v-icon',{key:(iBreadcrumb + "-divder"),attrs:{"dark":"","small":""}},[_vm._v(" mdi-chevron-right ")]):_vm._e()]})],2):_c('div',[_vm._v(" "+_vm._s(_vm.$t('select'))+" ")])],1)],1)]}}]),model:{value:(_vm.geoFile),callback:function ($$v) {_vm.geoFile=$$v},expression:"geoFile"}})],1)],1),_c('v-col',{staticClass:"file-col"},[_c('v-input',{attrs:{"value":_vm.paramFile,"rules":_vm.rulesParamFile,"hide-details":"auto"}},[_c('FileSelect',{attrs:{"simulation-model-uuid":_vm.model,"parent-maplayer-uuid":_vm.geoFile && _vm.geoFile.s3Uuid,"filter-parent-maplayer":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{staticClass:"secondary--text",staticStyle:{"max-width":"100%"},attrs:{"left":"","color":"transparent","content":"param","overlap":"","offset-x":"3em"}},[_c('v-chip',_vm._g(_vm._b({attrs:{"disabled":!_vm.geoFile,"color":"grey lighten-3"}},'v-chip',attrs,false),on),[(_vm.paramFile)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
var attrsTooltip = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate"},'div',attrsTooltip,false),onTooltip),[_vm._v(" "+_vm._s(_vm.paramFile.name)+" ")])]}}],null,true)},[_vm._l((_vm.paramFile.breadcrumbs),function(breadcrumb,iBreadcrumb){return [_c('span',{key:iBreadcrumb,staticClass:"text-no-wrap"},[_vm._v(_vm._s(breadcrumb))]),(iBreadcrumb < _vm.paramFile.breadcrumbs.length - 1)?_c('v-icon',{key:(iBreadcrumb + "-divder"),attrs:{"dark":"","small":""}},[_vm._v(" mdi-chevron-right ")]):_vm._e()]})],2):_c('div',[_vm._v(" "+_vm._s(_vm.$t('select'))+" ")])],1)],1)]}}]),model:{value:(_vm.paramFile),callback:function ($$v) {_vm.paramFile=$$v},expression:"paramFile"}})],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","color":"error","x-small":""},on:{"click":_vm.remove}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }