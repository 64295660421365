<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    :retain-focus="false"
    hide-overlay
    no-click-animation
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
      />
    </template>
    <v-form
      ref="form"
      v-model="isValid"
      :disabled="isLoading"
    >
      <v-card
        min-height="200"
      >
        <v-card-title
          class="
          draggable
          bg-ease
          white--text
          px-5
          py-1
        "
          style="font-size: 1rem;"
        >
          {{ $t('femwater') }}

          <v-spacer />

          <v-btn
            dark
            icon
            small
            @click="cancel"
          >
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-3">
          <!-- input zip -->
          <v-row
            class="mt-8 mb-12 "
            no-gutters
          >
            <v-col
              class="label-col"
              cols="auto"
            >
              <v-subheader class="align-start px-0">
                {{ $t('page.femwater.select_femwater_mesh_result_file') }}
              </v-subheader>
            </v-col>
            <v-col
              class="label-col"
              cols="auto"
            >
              <v-input
                :value="femwatermeshZip"
                :rules="rulesFile"
                hide-details="auto"
              >
                <FileSelect v-model="femwatermeshZip">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      color="primary"
                      small
                      :disabled="isLoading"
                      v-on="on"
                    >
                      {{ $t('select') }}
                    </v-btn>
                  </template>
                </FileSelect>
              </v-input>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-if="femwatermeshZip"
                class="flex-nowrap"
                color="primary"
                readonly
                filled
                single-line
              >
                <template #label>
                  <template v-for="(breadcrumb, iBreadcrumb) in femwatermeshZip.breadcrumbs">
                    <span
                      :key="iBreadcrumb"
                      class="text-no-wrap primary--text"
                      style="font-size: 0.9rem;"
                    >{{ breadcrumb }}</span>
                    <v-icon
                      v-if="iBreadcrumb < femwatermeshZip.breadcrumbs.length - 1"
                      :key="`${iBreadcrumb}-divder`"
                      color="primary"
                      small
                    >
                      mdi-chevron-right
                    </v-icon>
                  </template>
                </template>
                <template #append>
                  <v-btn
                    color="error"
                    icon
                    small
                    :disabled="isLoading"
                    @click="femwatermeshZip = null"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <!-- output mapset -->
          <v-row no-gutters>
            <v-col
              class="label-col"
              cols="auto"
            >
              <v-subheader class="align-start px-0">
                {{ $t('page.modflow.select_output_mapset') }}
              </v-subheader>
            </v-col>
            <v-col
              class="label-col"
              cols="auto"
            >
              <v-input
                :value="mapsetUuid"
                :rules="rulesFile"
                hide-details="auto"
              >
                <FileSelect
                  v-model="mapsetUuid"
                  :select-file-type="FILE_TYPE.MAPSET"
                  :permissions="['create']"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      color="primary"
                      small
                      :disabled="isLoading"
                      v-on="on"
                    >
                      {{ $t('select') }}
                    </v-btn>
                  </template>
                </FileSelect>
              </v-input>
            </v-col>
          </v-row>
          <v-text-field
            v-if="mapsetUuid"
            class="flex-nowrap"
            color="primary"
            readonly
            filled
            single-line
          >
            <template #label>
              <template v-for="(breadcrumb, iBreadcrumb) in mapsetUuid.breadcrumbs">
                <span
                  :key="iBreadcrumb"
                  class="text-no-wrap primary--text"
                  style="font-size: 0.9rem;"
                >{{ breadcrumb }}</span>
                <v-icon
                  v-if="iBreadcrumb < mapsetUuid.breadcrumbs.length - 1"
                  :key="`${iBreadcrumb}-divder`"
                  color="primary"
                  small
                >
                  mdi-chevron-right
                </v-icon>
              </template>
            </template>
            <template #append>
              <v-btn
                color="error"
                icon
                small
                @click="mapsetUuid = null"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-card-text>

        <v-card-actions class="pa-5">
          <v-spacer />

          <v-btn
            class="action-btn"
            rounded
            :disabled="isLoading"
            @click="cancel"
          >
            {{ $t('close') }}
          </v-btn>
          <v-btn
            class="action-btn"
            rounded
            color="primary"
            :loading="isLoading"
            @click="submit"
          >
            {{ $t('start') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import FileSelect from '@/components/FileSelect/FileSelect'
import { validateRequired } from '@/assets/js/validate'

import { SIM_MODEL } from '@/models'
import { FILE_TYPE } from '@/models/utils'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'FemwaterDialog',

  components: {
    FileSelect
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    SIM_MODEL,
    FILE_TYPE,
    show: false,
    isValid: false,
    isLoading: false,
    formData: {
      output: {
        mapsetUuid: null
      },
      input: {
        femwatermeshZip: null
      }
    },
    rulesFile: [validateRequired]
  }),

  computed: {
    ...mapState({
      model: state => state.simulation.model
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    dialog: {
      get() {
        return this.show
      },
      set(newVal) {
        this.show = newVal
        this.$emit('input', newVal)
      }
    },
    femwatermeshZip: {
      get() {
        return this.formData.input.femwatermeshZip
      },
      set(newVal) {
        this.formData.input.femwatermeshZip = newVal
      }
    },
    mapsetUuid: {
      get() {
        return this.formData.output.mapsetUuid
      },
      set(newVal) {
        this.formData.output.mapsetUuid = newVal
      }

    },

    project() {
      return this.getProjectByRoute(this.$route)
    }
  },

  watch: {
    value: {
      handler(newVal) {
        this.show = newVal
      },
      immediate: true
    },
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    close() {
      this.dialog = false
    },
    cancel() {
      this.close()
    },
    async submit() {
      await this.$refs.form.validate()

      if (!this.isValid) {
        return
      }

      this.isLoading = true
      return this.$store.dispatch('simulation/femwater/start', {
        project: this.project,
        taskData: this.formData
      })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_simulation')
          })
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.label-col {
  margin-right: 2em;
  width: 10em;
}

.v-btn.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}
</style>
