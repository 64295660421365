<template>
  <v-form
    ref="form"
    class="modflow-input-geo-form"
    @submit.prevent
  >
    <v-subheader>
      {{ $t('page.modflow.select_geo_param_files') }}
    </v-subheader>
    <v-row class="modflow-input-geo-row">
      <v-col
        class="modflow-input-geo-col"
        cols="4"
      >
        <fieldset>
          <legend class="text-h6">
            {{ $t('region') }}
            <v-btn
              class="add-btn"
              outlined
              icon
              x-small
              @click="addGeojsonItem(GEO_TYPE.POLYGON)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </legend>

          <v-input
            v-show="!geojsonList[GEO_TYPE.POLYGON].length"
            :rules="rulesRequiredPolygon"
            hide-details="auto"
          >
            <v-subheader
              class="warning--text"
            >
              {{ $t('page.modflow.select_at_least_one_area') }}
            </v-subheader>
          </v-input>

          <template v-for="(polygon,iPolygon) in geojsonList[GEO_TYPE.POLYGON]">
            <GeoParamsSelect
              ref="geoForms"
              :key="iPolygon"
              :geojson-item="polygon"
              @removeGeojsonItem="removeGeojsonItem(GEO_TYPE.POLYGON)(iPolygon)"
            />
            <v-divider :key="`${iPolygon}-divider`" />
          </template>
        </fieldset>
      </v-col>
      <v-col
        class="modflow-input-geo-col"
        cols="4"
      >
        <fieldset>
          <legend class="text-h6">
            {{ $t('line') }}
            <v-btn
              class="add-btn"
              outlined
              icon
              x-small
              @click="addGeojsonItem(GEO_TYPE.LINE_STRING)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </legend>

          <template v-for="(lineString,iLineString) in geojsonList[GEO_TYPE.LINE_STRING]">
            <GeoParamsSelect
              ref="geoForms"
              :key="iLineString"
              :geojson-item="lineString"
              @removeGeojsonItem="removeGeojsonItem(GEO_TYPE.LINE_STRING)(iLineString)"
            />
            <v-divider :key="`${iLineString}-divider`" />
          </template>
        </fieldset>
      </v-col>
      <v-col
        class="modflow-input-geo-col"
        cols="4"
      >
        <fieldset>
          <legend class="text-h6">
            {{ $t('well') }}
            <v-btn
              class="add-btn"
              outlined
              icon
              x-small
              @click="addGeojsonItem(GEO_TYPE.POINT)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </legend>

          <template v-for="(point,iPoint) in geojsonList[GEO_TYPE.POINT]">
            <GeoParamsSelect
              ref="geoForms"
              :key="iPoint"
              :geojson-item="point"
              @removeGeojsonItem="removeGeojsonItem(GEO_TYPE.POINT)(iPoint)"
            />
            <v-divider :key="`${iPoint}-divider`" />
          </template>
        </fieldset>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import GeoParamsSelect from '@/components/Simulation/GeoParamsSelect.vue'

import { getGeojsonItem, GEO_TYPE } from '@/store/modules/simulation/modules/modflow'

import { mapState } from 'vuex'

export default {
  name: 'ModflowInputs',

  components: {
    GeoParamsSelect
  },

  data: () => ({
    GEO_TYPE
  }),

  computed: {
    ...mapState({
      simData: state => state.simulation.modflow.simData
    }),
    rulesRequiredPolygon() {
      return [
        () => !!this.geojsonList[GEO_TYPE.POLYGON].length
      ]
    },

    geojsonList() {
      return this.simData.input.geojsonList
    }
  },

  methods: {
    validate() {
      if (!this.$refs.form) {
        return true
      }

      const geoForms = this.$refs.geoForms
      if (Array.isArray(geoForms)) {
        return Promise.all([
          this.$refs.form.validate(),
          ...geoForms.map(geoForm => geoForm.validate())
        ]).then(results => results.every(Boolean))
      }

      return this.$refs.form.validate()
    },
    resetValidation() {
      if (!this.$refs.form) {
        return
      }

      const geoForms = this.$refs.geoForms
      if (Array.isArray(geoForms)) {
        geoForms.forEach(geoForm => geoForm.resetValidation())
      }

      return this.$refs.form.resetValidation()
    },

    addGeojsonItem(type) {
      const newItem = getGeojsonItem()
      this.geojsonList[type].push(newItem)
    },
    removeGeojsonItem(type) {
      return iItem => this.geojsonList[type].splice(iItem, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.modflow-input-geo-form {
  height: 100%;

  .modflow-input-geo-row {
    height: calc(100% - 48px); // 扣掉v-subhead高度

    .modflow-input-geo-col {
      padding-top: 0;
      padding-bottom: 0;
      height: 100%;

      fieldset {
        display: inline-block;
        overflow: auto;
        padding: 0.7em;
        width: 100%;
        min-width: 0;
        height: 100%;

        legend {
          display: flex;
          align-items: center;
          padding: 0 0.3em;
          margin-left: 1.6em;

          .add-btn {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}
</style>
