<template>
  <v-app-bar
    class="map-bar"
    app
    dense
    :height="48"
  >
    <v-toolbar-title
      class="py-1"
      style="height: 100%;"
    >
      <v-img
        contain
        max-height="100%"
        src="@/assets/images/logo2.svg"
        style="cursor: pointer;"
        @click="toHomePage"
      />
    </v-toolbar-title>

    <FileManagerDialog
      v-on="$listeners"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          text
          tile
          x-large
          height="100%"
          v-on="on"
        >
          <v-icon left>
            mdi-folder-outline
          </v-icon>
          {{ $t('file_manager') }}
        </v-btn>
      </template>
    </FileManagerDialog>

    <SimulationDialog
      v-on="$listeners"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          class="d-none d-sm-inline-flex"
          text
          tile
          x-large
          height="100%"
          v-on="on"
        >
          <v-icon left>
            mdi-chevron-triple-right
          </v-icon>
          {{ $t('simulation') }}
        </v-btn>
      </template>
    </SimulationDialog>
    <v-btn
      class="d-none d-sm-inline-flex"
      text
      tile
      x-large
      height="100%"
      exact
      :disabled="!layerNodes.length"
      :to="{
        name: 'MapPostprocess',
        params: $route.params,
        query: $route.query
      }"
      @click="initPostprocess()"
    >
      <v-icon left>
        mdi-checkerboard-plus
      </v-icon>
      {{ $t('post_processing_tools') }}
    </v-btn>

    <!-- <AnimationDialog>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          text
          tile
          x-large
          height="100%"
          v-on="on"
        >
          <v-icon left>
            mdi-play-box-multiple
          </v-icon>
          製作動畫
        </v-btn>
      </template>
    </AnimationDialog> -->

    <v-btn
      class="d-none d-sm-inline-flex"
      text
      tile
      x-large
      height="100%"
      :to="{
        name:'MapDrawer',
        params: $route.params,
        query: $route.query
      }"
    >
      <v-icon left>
        mdi-pencil-ruler
      </v-icon>
      {{ $t('hand_drawn_tools') }}
    </v-btn>

    <v-spacer />

    <v-btn
      v-if="isMapModel"
      class="d-none d-sm-inline-flex"
      text
      tile
      x-large
      height="100%"
      :to="{
        name:'MapViewer',
        params: $route.params,
        query: $route.query
      }"
    >
      <v-icon left>
        mdi-eye
      </v-icon>
      {{ $t('viewer_model') }}
    </v-btn>

    <TranslationMenu>
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          class="d-none d-sm-inline-flex"
          text
          tile
          large
          height="100%"
          v-on="on"
        >
          <v-icon>
            mdi-translate
          </v-icon>
        </v-btn>
      </template>
    </TranslationMenu>

    <UserAvatar v-if="userName">
      <template #activator="{ on, attrs }">
        <v-btn
          class="bg-ease d-none d-sm-inline-flex"
          v-bind="attrs"
          dark
          text
          tile
          large
          height="100%"
          v-on="on"
        >
          <v-icon left>
            mdi-account-circle
          </v-icon>
          {{ userName }}
        </v-btn>
      </template>
    </UserAvatar>
  </v-app-bar>
</template>

<script>
import UserAvatar from '@/components/UserAvatar'
import TranslationMenu from '@/components/TranslationMenu'
import FileManagerDialog from '@/components/FileManager/FileManagerDialog'
import SimulationDialog from '@/components/Simulation/SimulationDialog'
// import AnimationDialog from '@/components/Map/Animation/AnimationDialog'
import { mapGetters } from 'vuex'

export default {
  name: 'MapBar',

  components: {
    UserAvatar,
    TranslationMenu,
    FileManagerDialog,
    SimulationDialog
    // AnimationDialog
  },

  inject: {
    mapNavHeight: {
      from: 'mapNavHeight',
      default: 48
    }
  },

  computed: {
    ...mapGetters({
      userName: 'user/userName',
      layerNodes: 'map/layerNodes',
      isMapModelByRoute: 'map/isMapModelByRoute'
    }),

    isMapModel() {
      return this.isMapModelByRoute(this.$route)
    }
  },

  created() {
    if (!this.userName) {
      this.fetchUser()
    }
  },

  methods: {
    fetchUser() {
      this.$store.dispatch('user/fetchUser')
    },
    toHomePage() {
      this.$router.push({
        name: 'Projects',
        params: this.$route.params,
        query: this.$route.query
      })
    },
    initPostprocess() {
      this.$store.dispatch('postprocess/init')
    }
  }

}
</script>

<style lang="scss" scoped>
.map-bar.v-app-bar.v-toolbar.v-sheet {
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.79);

  ::v-deep .v-toolbar__content {
    padding-right: 0;

    @media screen and (max-width: 600px) {
      padding-left: 0;
    }
  }
}
</style>
