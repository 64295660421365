<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    :retain-focus="false"
    hide-overlay
    no-click-animation
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
      />
    </template>
    <v-card
      class="massbalance-card"
      :loading="isLoading"
    >
      <v-card-title
        class="
            draggable
            bg-ease
            white--text
            px-5
            py-1
          "
        style="font-size: 1rem;"
      >
        <span>{{ $t('massbalance') }}</span>
        <span style="margin-left: 0.5em;">({{ step }}/{{ totalStep }})</span>
        <v-spacer />
        <v-btn
          dark
          icon
          small
          @click="cancel"
        >
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-stepper
          v-model="step"
          class="elevation-0"
        >
          <v-stepper-items>
            <v-stepper-content step="1">
              <MassbalanceInput ref="step1" />
            </v-stepper-content>
            <v-stepper-content step="2">
              <MassbalanceParams ref="step2" />
            </v-stepper-content>
            <v-stepper-content step="3">
              <MassbalanceOutputs ref="step3" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="action-btn"
          rounded
          :disabled="isLoading"
          @click="init"
        >
          {{ $t('reset') }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="step > 1"
          class="action-btn"
          color="primary"
          rounded
          :disabled="isLoading"
          @click="prevStep"
        >
          {{ $t('prev_step') }}
        </v-btn>
        <v-btn
          v-if="step < totalStep"
          class="action-btn"
          color="primary"
          rounded
          :disabled="isLoading || isLoadingFiles"
          @click="nextStep"
        >
          {{ $t('next_step') }}
        </v-btn>
        <v-btn
          v-if="step === totalStep"
          class="action-btn"
          color="primary"
          rounded
          :loading="isLoading"
          @click="submit"
        >
          {{ $t('start') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MassbalanceInput from './MassbalanceInput'
import MassbalanceParams from './MassbalanceParams'
import MassbalanceOutputs from './MassbalanceOutputs'

import { SIM_MODEL } from '@/models'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'MassbalanceDialog',

  components: {
    MassbalanceInput,
    MassbalanceParams,
    MassbalanceOutputs
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    SIM_MODEL,
    show: false,
    isLoading: false,
    step: 1,
    totalStep: 3
  }),

  computed: {
    ...mapState({
      model: state => state.simulation.model,
      simData: state => state.simulation.massbalance.simData
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    dialog: {
      get() {
        return this.show
      },
      set(newVal) {
        this.show = newVal
        this.$emit('input', newVal)
      }
    },

    project() {
      return this.getProjectByRoute(this.$route)
    },
    stepRefs() {
      return Array.from({ length: this.totalStep }, (_, i) => `step${i + 1}`)
    },
    hasNoPolygon() {
      return false
    },
    isLoadingFiles() {
      const geojsonList = this.$store.state.simulation.massbalance.simData.input.geojsonList

      return geojsonList.well?.isLoading === true || geojsonList.area?.isLoading === true
    }
  },

  watch: {
    value: {
      handler(newVal) {
        this.show = newVal
      },
      immediate: true
    },
    model: {
      handler() {
        this.init()
      },
      immediate: true
    },
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    init() {
      this.isLoading = true
      Promise.all([
        this.fetchDefaultWell(),
        this.$store.dispatch('simulation/massbalance/initSimData')
      ])
        .then(() => {
          this.step = 1
          this.$nextTick().then(this.resetValidation)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async fetchDefaultWell() {
      const folderName = '水利地理資訊圖資'
      const mapsetName = '地下水觀測井'
      const maplayerName = 'merged_地下水觀測井分層公版.json'
      const project = this.project

      const folders = await this.$store.dispatch('globalFiles/fetchFileNodes', {
        project
      }).catch(() => [])
      const folder = folders.find(f => f.name === folderName)

      if (!folder) return Promise.resolve()

      const mapsets = await this.$store.dispatch('globalFiles/fetchFileNodes', {
        project,
        parent: folder
      }).catch(() => [])
      const mapset = mapsets.find(f => f.name === mapsetName)

      if (!mapset) return Promise.resolve()

      const maplayers = await this.$store.dispatch('globalFiles/fetchFileNodes', {
        project,
        parent: mapset
      }).catch(() => [])
      const maplayer = maplayers.find(f => f.name === maplayerName)

      if (!maplayer) return Promise.resolve()

      this.simData.input.geojsonList.well = maplayer
      this.$store.dispatch('globalFiles/fetchFileContent', {
        project: this.project,
        fileNode: maplayer
      })
    },
    resetValidation() {
      this.stepRefs.map(ref => {
        return this.$refs?.[ref]?.resetValidation?.()
      })
    },
    async formValidate(ref) {
      const funValidate = this.$refs?.[ref]?.validate

      if (typeof funValidate !== 'function') {
        return true
      }

      return funValidate()
    },
    validate() {
      return Promise.all(
        this.stepRefs.map(ref => this.formValidate(ref))
      ).then(results => {
        // go to error step when submit
        const firstErrorStep = results.findIndex(v => !v) + 1
        if (firstErrorStep) {
          this.step = firstErrorStep
        }

        return results.every(Boolean)
      })
    },
    prevStep() {
      this.step -= 1
    },
    async nextStep() {
      const ref = `step${this.step}`
      const isValid = await this.formValidate(ref)

      if (!isValid) return

      this.step += 1
    },
    close() {
      this.dialog = false
    },
    cancel() {
      this.close()
    },
    async submit() {
      const isValid = await this.validate()

      if (!isValid) {
        return
      }

      this.isLoading = true
      return this.$store.dispatch('simulation/massbalance/start', {
        project: this.project
      })
        .then(glbFileNodes => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_simulation')
          })

          if (Array.isArray(glbFileNodes)) {
            glbFileNodes.forEach((fileNode, iFileNode) => {
              setTimeout(() => {
                this.$emit('fileContentLoaded', { fileNode })
              }, iFileNode * 800)
            })
          }
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })

          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.massbalance-card {
  .v-card__text {
    padding-top: 1rem;

    .v-stepper {
      .v-stepper__content {
        padding: 0;

        ::v-deep .v-stepper__wrapper {
          height: 460px;
        }
      }
    }
  }

  .v-card__actions {
    padding: 1rem;
  }
}

.v-btn.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}
</style>
