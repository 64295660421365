<template>
  <v-form
    ref="form"
    class="massbalance-input-form"
    @submit.prevent
  >
    <v-row
      class="mt-3 mb-12"
      no-gutters
    >
      <v-col
        class="label-col"
        :class="[$i18n.locale]"
        cols="auto"
      >
        <v-subheader class="align-start px-0">
          {{ $t('page.massbalance.select_well_file') }}
        </v-subheader>
      </v-col>
      <v-col cols="auto">
        <v-input
          class="ml-8"
          :value="well"
          :rules="rulesFile"
          hide-details="auto"
        >
          <FileSelect
            v-model="well"
            @change="fetchFileContent"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                color="primary"
                small
                v-on="on"
              >
                {{ $t('select') }}
              </v-btn>
            </template>
          </FileSelect>
        </v-input>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-if="well"
          class="flex-nowrap"
          color="primary"
          readonly
          filled
          single-line
          :loading="well.isLoading"
        >
          <template #label>
            <template v-for="(breadcrumb, iBreadcrumb) in well.breadcrumbs">
              <span
                :key="iBreadcrumb"
                class="text-no-wrap primary--text"
                style="font-size: 0.9rem;"
              >{{ breadcrumb }}</span>
              <v-icon
                v-if="iBreadcrumb < well.breadcrumbs.length - 1"
                :key="`${iBreadcrumb}-divder`"
                color="primary"
                small
              >
                mdi-chevron-right
              </v-icon>
            </template>
          </template>
          <template #append>
            <v-btn
              color="error"
              icon
              small
              @click="well = null"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
        class="label-col"
        :class="[$i18n.locale]"
        cols="auto"
      >
        <v-subheader class="align-start px-0">
          {{ $t('page.massbalance.select_area_file') }}
        </v-subheader>
      </v-col>
      <v-col cols="auto">
        <v-input
          class="ml-8"
          :value="area"
          :rules="rulesFile"
          hide-details="auto"
        >
          <FileSelect
            v-model="area"
            @change="fetchFileContent"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                color="primary"
                small
                v-on="on"
              >
                {{ $t('select') }}
              </v-btn>
            </template>
          </FileSelect>
        </v-input>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-if="area"
          class="flex-nowrap"
          color="primary"
          readonly
          filled
          single-line
          :loading="area.isLoading"
        >
          <template #label>
            <template v-for="(breadcrumb, iBreadcrumb) in area.breadcrumbs">
              <span
                :key="iBreadcrumb"
                class="text-no-wrap primary--text"
                style="font-size: 0.9rem;"
              >{{ breadcrumb }}</span>
              <v-icon
                v-if="iBreadcrumb < area.breadcrumbs.length - 1"
                :key="`${iBreadcrumb}-divder`"
                color="primary"
                small
              >
                mdi-chevron-right
              </v-icon>
            </template>
          </template>
          <template #append>
            <v-btn
              color="error"
              icon
              small
              @click="area = null"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import FileSelect from '@/components/FileSelect/FileSelect'
import { SIM_MODEL } from '@/models'
import { validateRequired } from '@/assets/js/validate'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'MassbalanceInput',

  components: {
    FileSelect
  },

  data: () => ({
    SIM_MODEL,
    rulesFile: [validateRequired]
  }),

  computed: {
    ...mapState({
      model: state => state.simulation.model,
      simData: state => state.simulation.massbalance.simData
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    well: {
      get() {
        return this.simData.input.geojsonList.well
      },
      set(newVal) {
        this.$set(this.simData.input.geojsonList, 'well', newVal)
      }
    },
    area: {
      get() {
        return this.simData.input.geojsonList.area
      },
      set(newVal) {
        this.$set(this.simData.input.geojsonList, 'area', newVal)
      }
    },

    project() {
      return this.getProjectByRoute(this.$route)
    }
  },

  methods: {
    validate() {
      if (!this.$refs.form) {
        return true
      }
      return this.$refs.form.validate()
    },
    resetValidation() {
      if (!this.$refs.form) {
        return
      }

      return this.$refs.form.resetValidation()
    },
    fetchFileContent(fileNode) {
      return this.$store.dispatch('globalFiles/fetchFileContent', {
        project: this.project,
        fileNode: fileNode
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.modflow-input-model-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  & > div {
    flex-grow: 0;
  }
}

.label-col {
  width: 9em;

  &.en {
    width: 14em;
  }
}
</style>
