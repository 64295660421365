<template>
  <v-form
    ref="form"
    class="massbalance-input-form"
    @submit.prevent
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model.number="grid.nx"
            type="number"
            label="nx"
            outlined
            :rules="rulesInt"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.number="grid.ny"
            type="number"
            label="ny"
            outlined
            :rules="rulesInt"
          />
        </v-col>
        <v-col
          v-if="!!wellLayerLabelOtions.length"
          cols="12"
        >
          <v-select
            v-model="layer"
            :rules="rulesWellLayer"
            :items="wellLayerLabelOtions"
            :label="$t('page.massbalance.well_layer_count')"
            outlined
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { SIM_MODEL } from '@/models'
import { FEATURE_MULTI_LAYER } from '@/models/utils'
import { validateRequired } from '@/assets/js/validate'

import * as turf from '@turf/turf'

import { mapState } from 'vuex'

export default {
  name: 'MassbalanceParams',

  data: () => ({
    SIM_MODEL,
    rulesInt: [validateRequired],
    rulesWellLayer: [validateRequired]
  }),

  computed: {
    ...mapState({
      model: state => state.simulation.model,
      simData: state => state.simulation.massbalance.simData
    }),

    layer: {
      get() {
        return this.simData.massbalance.layer
      },
      set(newVal) {
        this.simData.massbalance.layer = newVal
      }
    },

    grid() {
      return this.simData.massbalance.grid
    },
    wellLayerLabelOtions() {
      const wellFeatures = this.simData.input.geojsonList.well?.fileContent?.features
      const areaFeature = this.simData.input.geojsonList.area?.fileContent?.features?.[0]

      if (
        !Array.isArray(wellFeatures) ||
        !areaFeature ||
        areaFeature?.geometry?.type?.toLowerCase?.() !== 'polygon'
      ) {
        return []
      }

      // 取出area內的points, 查找layer labels
      const turfPolygon = turf.polygon(areaFeature.geometry.coordinates)
      const wellInsideAreaFeatures = wellFeatures.filter(wf => {
        const type = wf.geometry.type
        if (type.toLowerCase?.() !== 'point') return false

        const turfPoint = turf.point(wf.geometry.coordinates)

        return turf.booleanPointInPolygon(turfPoint, turfPolygon)
      })

      const layers = wellInsideAreaFeatures
        .filter(FEATURE_MULTI_LAYER.is.bind(FEATURE_MULTI_LAYER))
        .flatMap(feature => FEATURE_MULTI_LAYER.getLayerProperties(feature))
      const unitLayerFeatures = [
        ...new Set(layers.map(layer => layer[2]))
      ]
      return unitLayerFeatures.map(layerLabel => {
        const targetLayer = layers.find(layer => layer[2] === layerLabel)

        if (!targetLayer) return null

        return {
          value: targetLayer[0],
          text: targetLayer[2]
        }
      }).filter(Boolean)
    }
  },

  methods: {
    validate() {
      if (!this.$refs.form) {
        return true
      }
      return this.$refs.form.validate()
    },
    resetValidation() {
      if (!this.$refs.form) {
        return
      }

      return this.$refs.form.resetValidation()
    }
  }
}
</script>

<style lang="scss" scoped>
.modflow-input-model-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  & > div {
    flex-grow: 0;
  }
}

.label-col {
  width: 9em;

  &.en {
    width: 14em;
  }
}
</style>
